import React, { useEffect, useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import defaultUserImage from "assets/images/defaultUserIcon.png";
import config from "config";
import { useQuery } from "hooks/use-query";
import { useTranslations } from "hooks/use-translations";
import { caregiversActions } from "redux/caregivers/actions";

import {
  Avatar,
  ContentContainer,
  FourthHeading,
  MainSpinner,
  MobileSection,
  StyledLayout,
  Text,
} from "components/common.styled";
import Rates from "components/common/rates";
import Reviews from "components/common/reviews";
import Footer from "components/footer";
import Header from "components/header";

import AvatarModal from "../components/avatar-modal";
import Breadcrumbs from "../components/breadcrumbs";
import Education from "../components/education";
import ErrorMessage from "../components/error-message";
import Languages from "../components/languages";
import ProfessionalStatement from "../components/professional-statement";
import Schedule from "../components/schedule";
import Specialities from "../components/specialities";

import {
  AvatarSection,
  BioTitle,
  Content,
  FixedContainer,
  LocalStyledDivider,
  MainRate,
  MainSectionWrapper,
  NameTitle,
  P,
  ReviewsButton,
  StyledRate,
  TabHeader,
  TabRow,
} from "./index.styled";

const Caregiver = () => {
  const { caregiver, error, loading, loadingReviews, pageReviews, reviews } =
    useSelector((state) => state.caregivers);

  const dispatch = useDispatch();
  const location = useLocation();
  const { name, role, caregiver_id: caregiverId } = useParams();
  const query = useQuery();

  const reviewsTitle = useRef(null);

  const [isModalOpened, setIsModalOpened] = useState(false);

  const routeName = "[first_param].[second_param].p.[caregiver_id]";
  const translations = useTranslations(routeName);

  const fullName = useMemo(
    () =>
      `${caregiver?.attributes?.first_name || ""} ${
        caregiver?.attributes?.last_name || ""
      }`,
    [caregiver?.attributes?.first_name, caregiver?.attributes?.last_name],
  );

  const caregiverOverallRating = useMemo(
    () =>
      Math.round(
        ((caregiver?.attributes?.overall_rating || 0) + Number.EPSILON) * 100,
      ) / 100,
    [caregiver?.attributes?.overall_rating],
  );

  const scrollToReviews = () => {
    if (reviewsTitle) {
      window.scrollTo({
        behavior: "smooth",
        top: reviewsTitle.current.offsetTop,
      });
    }
  };

  const toggleModal = () => setIsModalOpened((prev) => !prev);

  useEffect(() => {
    if (caregiverId) {
      dispatch(caregiversActions.getCaregiverById(caregiverId));
      dispatch(caregiversActions.fetchCaregiverProcedures(caregiverId));
    }
  }, [caregiverId, dispatch]);

  useEffect(() => {
    if (query.get("page") && +query.get("page") !== +pageReviews)
      dispatch(
        caregiversActions.changePageCaregiverReviews(+query.get("page")),
      );
  }, [dispatch, pageReviews, query]);

  useEffect(() => {
    if (caregiverId)
      dispatch(
        caregiversActions.fetchCaregiverReviews({
          id: caregiverId,
          page: pageReviews,
        }),
      );
  }, [caregiverId, dispatch, pageReviews]);

  useEffect(() => {
    return () => {
      dispatch(caregiversActions.changePageCaregiverReviews(1));
      dispatch(caregiversActions.cleanUpCaregiver());
    };
  }, [dispatch]);

  return (
    <StyledLayout itemScope itemType="https://schema.org/Physician">
      {caregiver.attributes && (
        <Helmet>
          <title>{`${caregiver.role?.attributes?.name || ""} ${fullName}, ${
            caregiver.clinics?.length > 0 &&
            caregiver.clinics[0]?.attributes?.clinic_city
          } | ${translations.meta_title_booking}`}</title>
          <meta
            id="meta-description"
            name="description"
            content={`${
              translations.book_time_now_with
            } ${caregiver.role?.attributes?.name?.toLowerCase()} ${fullName} ${
              translations.in
            } ${
              caregiver.clinics?.length > 0 &&
              caregiver.clinics[0]?.attributes?.clinic_city
            }${
              translations.meta_caregiver_page_description
            } ${role?.toLowerCase()}.`}
          />
          <meta
            id="meta-og-type"
            property="og:type"
            content={caregiver.role?.attributes?.name || ""}
          />
          <meta
            id="meta-og-title"
            property="og:title"
            content={`${fullName}, ${caregiver.role?.attributes?.name}`}
          />
          <meta
            id="meta-og-url"
            property="og:url"
            content={config.url + location.pathname.replace(/\?.*$/gm, "")}
          />
          <meta
            id="meta-og-image"
            property="og:image"
            content={
              caregiver.default_user_image
                ? caregiver.default_user_image?.attributes?.original
                : defaultUserImage
            }
          />
          <link
            id="canonical-link"
            rel="canonical"
            href={config.url + location.pathname.replace(/\?.*$/gm, "")}
          />
        </Helmet>
      )}
      <Header
        addPaddingBottom
        showSearchBar
        isStyledPage
        translations={translations}
      />
      <ContentContainer isStyledPage>
        <FixedContainer isStyledPage>
          {loading && <MainSpinner />}
          {!error && caregiver.attributes && (
            <Content>
              <meta itemProp="name" content={fullName} />
              <MainSectionWrapper style={{ padding: "20px 0 0" }}>
                <AvatarSection>
                  {!caregiver.default_user_image && (
                    <Avatar
                      size={global.innerWidth < 994 ? 114 : 146}
                      alt={`${caregiver.role?.attributes?.name} ${fullName}`}
                      border
                      itemProp="image"
                      src={defaultUserImage}
                    />
                  )}
                  {caregiver.default_user_image && (
                    <Avatar
                      size={global.innerWidth < 994 ? 114 : 146}
                      alt={`${caregiver.role?.attributes?.name} ${fullName}`}
                      border
                      itemProp="image"
                      onClick={toggleModal}
                      style={{ cursor: "pointer" }}
                      src={caregiver.default_user_image?.attributes?.original}
                    />
                  )}

                  {global.innerWidth >= 994 && (
                    <NameTitle>{fullName}</NameTitle>
                  )}
                  {global.innerWidth < 994 && (
                    <NameTitle style={{ fontSize: "20px", lineHeight: "24px" }}>
                      {fullName}
                    </NameTitle>
                  )}

                  {!caregiver?.attributes?.hide_reviews &&
                    reviews?.data?.length > 0 &&
                    caregiver?.attributes?.overall_rating > 0 && (
                      <div
                        itemProp="aggregateRating"
                        itemScope
                        itemType="https://schema.org/AggregateRating"
                        onClick={scrollToReviews}
                        style={{ cursor: "pointer", textAlign: "center" }}
                      >
                        <meta content={caregiver.attributes?.overall_rating} />
                        <meta content="1" itemProp="worstRating" />
                        <meta content="5" itemProp="bestRating" />
                        <meta content={fullName} itemProp="itemReviewed" />
                        <StyledRate
                          defaultValue={caregiverOverallRating}
                          size={28}
                          margin="0 2px 0 0"
                        />
                        <P>
                          <span itemProp="ratingValue">
                            {caregiverOverallRating}
                          </span>
                          {` - `}
                          <span itemProp="reviewCount">
                            {caregiver.attributes?.number_of_reviews}
                          </span>{" "}
                          {translations.reviews}
                        </P>
                      </div>
                    )}
                  {caregiver.attributes?.hide_reviews && (
                    <div
                      style={{
                        color: "#000000a6",
                        fontSize: "13px",
                        lineHeight: "13px",
                      }}
                    >
                      {translations.user_reviews_falseouteName}
                    </div>
                  )}

                  {global.innerWidth >= 994 && (
                    <FourthHeading
                      style={{
                        fontSize: 20,
                        lineHeight: "28px",
                        marginTop: 10,
                      }}
                    >
                      {caregiver.attributes?.title ||
                        caregiver.role?.attributes?.name}
                    </FourthHeading>
                  )}
                  {global.innerWidth < 994 && (
                    <div
                      style={{
                        lineHeight: "28px",
                        marginBottom: "20px",
                        color: "#484848",
                        fontSize: 15,
                      }}
                    >
                      {caregiver.attributes?.title ||
                        caregiver.role.attributes?.name}
                    </div>
                  )}
                  {isModalOpened && (
                    <AvatarModal
                      onClose={toggleModal}
                      avatar={
                        caregiver.default_user_image?.attributes?.original
                      }
                    />
                  )}
                </AvatarSection>

                {window.innerWidth < 900 && caregiverId && (
                  <Schedule translations={translations} />
                )}

                <TabRow>
                  <TabHeader>{translations.overview}</TabHeader>
                  {!caregiver.attributes?.hide_reviews &&
                    caregiver.attributes?.number_of_reviews > 0 && (
                      <ReviewsButton secondary onClick={scrollToReviews}>
                        {translations.patient_reviews}
                      </ReviewsButton>
                    )}
                </TabRow>

                <div>
                  <Breadcrumbs
                    translations={translations}
                    fullName={fullName}
                    roleName={caregiver?.role?.attributes?.name}
                    roleId={caregiver?.role?.id}
                  />
                  {caregiver.educations?.length > 0 && (
                    <Education
                      educations={caregiver.educations}
                      translations={translations}
                    />
                  )}
                  {caregiver.specialities?.length > 0 && (
                    <Specialities
                      translations={translations}
                      specialities={caregiver.specialities}
                    />
                  )}
                  {caregiver.languages?.length > 0 && (
                    <Languages
                      translations={translations}
                      languages={caregiver.languages}
                    />
                  )}
                  {caregiver.attributes?.professional_statement && (
                    <ProfessionalStatement
                      translations={translations}
                      professionalStatement={
                        caregiver.attributes?.professional_statement
                      }
                    />
                  )}
                  <LocalStyledDivider />
                  {!caregiver?.attributes?.hide_reviews &&
                    !!caregiver?.attributes?.overall_rating && (
                      <>
                        <MobileSection>
                          <BioTitle ref={reviewsTitle}>
                            {caregiver.attributes?.number_of_reviews}{" "}
                            {translations.reviews_from_confirmed_patients}
                          </BioTitle>
                          <MainRate
                            margin="0 2px 0 0"
                            defaultValue={caregiver.attributes?.overall_rating}
                          />
                          <P>{translations.all_reviews_are_genuine}</P>
                        </MobileSection>
                        <LocalStyledDivider />
                        <MobileSection>
                          <Rates
                            starMargin="0 2px 0 0"
                            starSize={20}
                            item={caregiver}
                            translations={translations}
                          />
                        </MobileSection>
                        <LocalStyledDivider />
                        <Reviews
                          loading={loadingReviews}
                          reviews={reviews}
                          pageReviews={pageReviews}
                          dispatchPage={(page) =>
                            dispatch(
                              caregiversActions.changePageCaregiverReviews(
                                page,
                              ),
                            )
                          }
                          pathname={`/${role}/${name}/p/${caregiverId}`}
                          translations={translations}
                        />
                      </>
                    )}
                  {caregiver.attributes?.hide_reviews && (
                    <div
                      style={{
                        color: "#000000a6",
                        margin: "0 0 50px",
                        fontSize: "13px",
                        lineHeight: "13px",
                      }}
                    >
                      {translations.user_reviews_falseouteName}
                    </div>
                  )}
                  <MobileSection style={{ paddingBottom: 0 }}>
                    <div style={{ textAlign: "start" }}>
                      <Text>
                        {translations.are_you} {fullName}?{" "}
                        <a href="https://app.muntra.com/">
                          {translations.change_your_profile}{" "}
                        </a>
                      </Text>
                      <Breadcrumbs
                        translations={translations}
                        fullName={fullName}
                        roleName={caregiver?.role?.attributes?.name}
                        roleId={caregiver?.role?.id}
                      />
                    </div>
                  </MobileSection>
                </div>
              </MainSectionWrapper>
              {window.innerWidth >= 900 && caregiverId && (
                <Schedule translations={translations} />
              )}
            </Content>
          )}
          {error && !loading && (
            <ErrorMessage error={error} translations={translations} />
          )}
        </FixedContainer>
      </ContentContainer>
      <Footer translations={translations} />
    </StyledLayout>
  );
};

export default Caregiver;
