import React, { memo } from "react";
import { Link } from "react-router-dom";
import * as PropTypes from "prop-types";

import { slugifyer } from "helpers/helper-functions";

import {
  CategoryContainer,
  CategoryImg,
  ImageContainer,
} from "../../container/index.styled";

const TreatmentTypeCard = ({
  roleProcedureId,
  roleProcedure,
  roleProcedureName,
}) => (
  <CategoryContainer key={roleProcedureId}>
    <Link to={`/${slugifyer(roleProcedure)}/rp/${roleProcedureId}`}>
      <ImageContainer>
        <CategoryImg
          alt={roleProcedure}
          loading="lazy"
          src={`https://public.muntra.com/roleProcedures/${roleProcedureId}.jpeg`}
        />
      </ImageContainer>
      <p>{roleProcedureName}</p>
    </Link>
  </CategoryContainer>
);

TreatmentTypeCard.propTypes = {
  roleProcedureId: PropTypes.string,
  roleProcedure: PropTypes.string,
  roleProcedureName: PropTypes.string,
};

export default memo(TreatmentTypeCard);
