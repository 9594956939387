import React, { memo, useCallback, useMemo, useState } from "react";
import * as PropTypes from "prop-types";

import CheckMarkIcon from "assets/svg-components/CheckMarIcon";
import { useDebounce } from "hooks/use-debounce";

const CheckBoxesAndTextAreaContent = ({
  checkBoxPropertiesArray,
  header,
  otherText,
  setDeclarationItems,
  setOtherText,
  showCheckBoxes,
  textAreaLabel,
  textAreaPlaceholder,
}) => {
  const [currentText, setCurrentText] = useState(otherText);

  const handleInputChange = ({ target }) => {
    setCurrentText(target.value);
  };

  const checkBoxChecker = useCallback(
    (id) => {
      setDeclarationItems((previousDeclarationItems) => {
        return previousDeclarationItems.map((item) =>
          item.id === id
            ? {
                ...item,
                attributes: {
                  ...item.attributes,
                  value: !item.attributes.value,
                },
              }
            : item,
        );
      });
    },
    [setDeclarationItems],
  );

  const renderCheckboxes = useMemo(
    () =>
      checkBoxPropertiesArray?.map((checkBoxObject) => {
        const { id, attributes: { value, label } = {} } = checkBoxObject || {};

        if (!label) {
          return;
        }

        return (
          <div
            className="flexRowCenter HealthDeclarationSmileyDiv"
            key={id}
            onClick={() => checkBoxChecker(id)}
            style={{ marginBottom: "15px" }}
          >
            <div className="HealthDeclarationCheckBox">
              <CheckMarkIcon
                classProp={value ? "displayBlock" : "displayNone"}
              />
            </div>
            <label style={{ marginLeft: "5px" }}>{label}</label>
          </div>
        );
      }),
    [checkBoxChecker, checkBoxPropertiesArray],
  );

  const debounceTextCb = useCallback(() => {
    setOtherText(currentText);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentText]);

  useDebounce(debounceTextCb, [currentText], 100);

  return (
    <div
      className={`${showCheckBoxes ? "" : "FlexColJustCenter80vh"}`}
      style={{ width: "300px", height: showCheckBoxes ? "" : "80vh" }}
    >
      <h2
        className="bookingHeader"
        style={{ marginBottom: showCheckBoxes ? "1.5em" : "" }}
      >
        {header}
      </h2>
      {showCheckBoxes && (
        <div className="HealthDeclarationMedicineCheckboxColumn">
          {renderCheckboxes}
        </div>
      )}
      <div
        className="flexColStart"
        style={{
          marginTop: showCheckBoxes ? "5vh" : "0px",
          textAlign: "left",
        }}
      >
        <label>{textAreaLabel}</label>
        <span className="SubLabel">{textAreaPlaceholder}</span>
        <textarea
          className="HealthDeclarationTextArea"
          onChange={handleInputChange}
          value={currentText || ""}
        />
      </div>
    </div>
  );
};

CheckBoxesAndTextAreaContent.propTypes = {
  checkBoxPropertiesArray: PropTypes.array,
  header: PropTypes.string,
  otherText: PropTypes.string,
  setDeclarationItems: PropTypes.func,
  setOtherText: PropTypes.func,
  showCheckBoxes: PropTypes.bool,
  textAreaLabel: PropTypes.string,
  textAreaPlaceholder: PropTypes.string,
};

export default memo(CheckBoxesAndTextAreaContent);
