import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { addDays, endOfDay } from "date-fns";
import {
  Alink,
  FakeSearchContainer,
  FakeSearchDiv,
  FakeSearchSpan,
  Layout,
  LocalCaregiversHeader,
} from "style/global-styled-components.styled";

import GeoLocationSvgMobile from "assets/svg-components/GeoLocationMobile";
import SearchSvg from "assets/svg-components/Search";
import config from "config";
import { mobileValidator, slugifyer, toDate } from "helpers/helper-functions";
import { useDebounce } from "hooks/use-debounce";
import { useTranslations } from "hooks/use-translations";
import { caregiversActions } from "redux/caregivers/actions";
import { googlePlacesActions } from "redux/google-places/actions";
import { rolesActions } from "redux/roles/actions";

import { ContentContainer, MainSpinner, Text } from "components/common.styled";
import Footer from "components/footer";
import Header from "components/header";

import CaregiverTypeCard from "../components/caregiver-type-card";
import ErrorMessage from "../components/error";
import NearestCaregiverCard from "../components/nearest-caregiver-card";
import SearchBar from "../components/search-bar";
import SearchCaregiversModal from "../components/search-caregivers-modal-mobile";
import SearchLocationModal from "../components/search-location-modal-mobile";
import TreatmentContent from "../components/treatment-content";
import TreatmentTypeCard from "../components/treatment-type-card";

import {
  CaregiverTypeCardDeck,
  CaregiverTypeCards,
  FakeSearchButton,
  Grid,
  InnerContainer,
  LocalCaregiverList,
  LowerContainer,
  MainHeading,
  PlacesContainer,
  SearchBarHeaderHeading,
  SearchBarHeaderHeadingMobile,
  UpperContainer,
} from "./index.styled";

const ROUTE_NAME = "start";

const StartPage = () => {
  const googlePlacesState = useSelector((state) => state.googlePlaces);
  const { data } = useSelector((state) => state.app);
  const { searchSlotsLoading, firstStartSlots, secondStartSlots, error } =
    useSelector((state) => state.caregivers);

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const translations = useTranslations(ROUTE_NAME);

  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isGeo, setIsGeo] = useState(false);
  const [isMobile, setIsMobile] = useState(true);
  const [pageIsRendered, setPageIsRendered] = useState(false);
  const [isCaregiverSearchModalOpened, setIsCaregiverSearchModalOpened] =
    useState(false);
  const [isLocationSearchModalOpened, setIsLocationSearchModalOpened] =
    useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [debouncedStartDate, setDebouncedStartDate] = useState(startDate);

  const timeStep = global.innerWidth < 994 ? 1 : 3;
  const endDateInterval = global.innerWidth < 994 ? 0 : 2;

  const firstProcedure = {
    id: config.defaultProcedureId,
    title: config.defaultProcedureTitle,
  };

  const secondProcedure = {
    id: 4,
    title: translations.start_emergency_dentist_treatment,
  };

  const getEndDate = useCallback(
    () => addDays(endOfDay(debouncedStartDate), endDateInterval),
    [endDateInterval, debouncedStartDate],
  );

  const toggleCaregiverSearchModal = () =>
    setIsCaregiverSearchModalOpened(!isCaregiverSearchModalOpened);

  const toggleLocationSearchModal = () =>
    setIsLocationSearchModalOpened(!isLocationSearchModalOpened);

  const goToDate = (date) => {
    toDate(date, setStartDate);
  };

  const debounceDateCb = useCallback(() => {
    if (startDate !== debouncedStartDate) {
      setDebouncedStartDate(startDate);
    }
  }, [debouncedStartDate, startDate]);

  useDebounce(debounceDateCb, [], 400);

  useEffect(() => {
    dispatch(
      rolesActions.fetchRoles({
        isCaregiverRole: true,
        include: "default_procedure",
      }),
    );
    dispatch(
      googlePlacesActions.fetchGooglePlacesMain({
        id: "ChIJ8fA1bTmyXEYRYm-tjaLruCI",
        include: "child_places",
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    const isGeo = localStorage?.getItem("muntra-geolocation-status");
    const lat = localStorage?.getItem("muntra-latitude");
    const lng = localStorage?.getItem("muntra-longitude");

    setLatitude(lat);
    setLongitude(lng);
    setIsGeo(isGeo);

    setIsMobile(mobileValidator.test(window.navigator.userAgent));

    setPageIsRendered(true);
  }, []);

  useEffect(() => {
    const lat = localStorage?.getItem("muntra-latitude");
    const lng = localStorage?.getItem("muntra-longitude");

    if (lat && lng) {
      const placesProps = {
        lat,
        lng,
      };
      const procedure = [config.defaultProcedureId, 4];

      if (isGeo) {
        dispatch(
          caregiversActions.fetchNearestCaregiverSlots({
            radius: 3000,
            onlyWithImg: true,
            hasFreeSlot: 3,
            dateEnd: getEndDate(),
            dateStart: debouncedStartDate,
            page: 1,
            procedureIds: procedure,
            roleIds: "",
            ...placesProps,
          }),
        );
      }
    }
  }, [debouncedStartDate, dispatch, getEndDate, isGeo]);

  return (
    <Layout>
      <Helmet>
        <title>{translations.meta_title_start}</title>
        <meta
          id="meta-description"
          name="description"
          content={translations.meta_content_clinic_dir}
        />
        <link
          id="canonical-link"
          rel="canonical"
          href={config.url + location.pathname}
        />
      </Helmet>
      <Header translations={translations} showSearchBar={false} />
      <div className="StartPageBackground StartPageMainDiv">
        {pageIsRendered && (
          <UpperContainer>
            {!isMobile && (
              <>
                <SearchBarHeaderHeading>
                  <MainHeading>{translations.start_page_header}</MainHeading>
                </SearchBarHeaderHeading>
                <SearchBar
                  isStartPage
                  isStyledPage
                  routeName={ROUTE_NAME}
                  translations={translations}
                />
              </>
            )}
            {isMobile && (
              <>
                <SearchBarHeaderHeadingMobile>
                  <MainHeading className="FakeSearchHeader">
                    {translations.start_page_header}
                  </MainHeading>
                </SearchBarHeaderHeadingMobile>
                <FakeSearchContainer>
                  <FakeSearchDiv onClick={toggleCaregiverSearchModal}>
                    <div className="SVGContainer">
                      <SearchSvg />
                    </div>
                    <FakeSearchSpan>
                      {translations.caregiver_search_span}
                    </FakeSearchSpan>
                  </FakeSearchDiv>
                  <FakeSearchDiv onClick={toggleLocationSearchModal}>
                    <div className="SVGContainer">
                      <GeoLocationSvgMobile />
                    </div>
                    <FakeSearchSpan>
                      {translations.location_search_span}
                    </FakeSearchSpan>
                  </FakeSearchDiv>
                  <FakeSearchButton onClick={() => history.push("/search")}>
                    {translations.search_text}
                  </FakeSearchButton>
                </FakeSearchContainer>
              </>
            )}
          </UpperContainer>
        )}
        {latitude && longitude && isGeo && !error && (
          <ContentContainer>
            <InnerContainer>
              {firstStartSlots.results && (
                <LocalCaregiversHeader>
                  {" "}
                  {translations.book_base_examination_dentist}
                </LocalCaregiversHeader>
              )}
              {firstStartSlots.results && (
                <Grid style={{ marginBottom: "100px" }}>
                  {firstStartSlots.results.map((slotDetails) => (
                    <NearestCaregiverCard
                      key={slotDetails?.id}
                      defaultProcedure={firstProcedure}
                      slotDetails={slotDetails}
                      searchSlotsLoading={searchSlotsLoading}
                      dateObject={{ startDate, timeStep, goToDate }}
                      translations={translations}
                    />
                  ))}
                </Grid>
              )}
              {secondStartSlots.results && (
                <LocalCaregiversHeader>
                  {translations.book_emergency_dentist_time}{" "}
                </LocalCaregiversHeader>
              )}
              {secondStartSlots.results && (
                <Grid>
                  {secondStartSlots.results.map((slotDetails) => (
                    <NearestCaregiverCard
                      key={slotDetails?.id}
                      defaultProcedure={secondProcedure}
                      slotDetails={slotDetails}
                      searchSlotsLoading={searchSlotsLoading}
                      translations={translations}
                      dateObject={{ startDate, timeStep, goToDate }}
                    />
                  ))}
                </Grid>
              )}
              {!firstStartSlots.results && !secondStartSlots.results && (
                <MainSpinner style={{ margin: "20vh auto 10vh" }} />
              )}
            </InnerContainer>
          </ContentContainer>
        )}
        <ContentContainer style={{ minHeight: "268px !important" }}>
          <InnerContainer>
            <CaregiverTypeCardDeck>
              <LocalCaregiversHeader>
                {translations.find_treatment_by_category}
              </LocalCaregiversHeader>
              <CaregiverTypeCards>
                <TreatmentTypeCard
                  roleProcedureId="1"
                  roleProcedure={translations.dental_care}
                  roleProcedureName={translations.dental_care}
                />
                <TreatmentTypeCard
                  roleProcedureId="4"
                  roleProcedure={translations.botox_treatment}
                  roleProcedureName={translations.botox}
                />
                <TreatmentTypeCard
                  roleProcedureId="36"
                  roleProcedure={translations.tartar_removal}
                  roleProcedureName={translations.tartar}
                />
                <TreatmentTypeCard
                  roleProcedureId="39"
                  roleProcedure={translations.tooth_pain}
                  roleProcedureName={translations.tooth_pain}
                />
                <TreatmentTypeCard
                  roleProcedureId="3"
                  roleProcedure={translations.emergency_dental_care}
                  roleProcedureName={translations.emergency_dental_care}
                />
                <TreatmentTypeCard
                  roleProcedureId="10"
                  roleProcedure={translations.base_examination_dentist}
                  roleProcedureName={translations.examination}
                />
                <TreatmentTypeCard
                  roleProcedureId="11"
                  roleProcedure={translations.teeth_whitening}
                  roleProcedureName={translations.teeth_whitening}
                />
                <TreatmentTypeCard
                  roleProcedureId="41"
                  roleProcedure={translations.braces}
                  roleProcedureName={translations.braces}
                />
                <TreatmentTypeCard
                  roleProcedureId="42"
                  roleProcedure={translations.invisalign_invisible_braces}
                  roleProcedureName={translations.invisalign}
                />
              </CaregiverTypeCards>
            </CaregiverTypeCardDeck>
          </InnerContainer>
        </ContentContainer>
      </div>
      {data === "sv" && (
        <ContentContainer style={{ minHeight: "268px " }} isStyledPage>
          <InnerContainer>
            <CaregiverTypeCardDeck>
              <LocalCaregiversHeader>
                Innan du bokar behandlingen{" "}
              </LocalCaregiversHeader>
              <CaregiverTypeCards>
                <TreatmentContent
                  treatmentContentName="Botox"
                  treatmentParam="botox"
                  imageUrl="https://public.muntra.com/treatmentContent/botox.jpeg"
                />
                <TreatmentContent
                  treatmentContentName="Tandblekning"
                  treatmentParam="teeth-whitening"
                  imageUrl="https://public.muntra.com/treatmentContent/bleka-tander.jpeg"
                />
              </CaregiverTypeCards>
            </CaregiverTypeCardDeck>
          </InnerContainer>
        </ContentContainer>
      )}
      <ContentContainer style={{ minHeight: "268px" }} isStyledPage>
        <InnerContainer>
          <CaregiverTypeCardDeck>
            <LocalCaregiversHeader>
              {translations.find_caregiver_by_occupation}{" "}
            </LocalCaregiversHeader>
            <CaregiverTypeCards>
              <CaregiverTypeCard
                role={translations.start_caregiver_card_dentist}
                roleId="3"
              />
              <CaregiverTypeCard
                role={translations.start_caregiver_card_dental_hygienist}
                roleId="4"
              />
            </CaregiverTypeCards>
          </CaregiverTypeCardDeck>
        </InnerContainer>
      </ContentContainer>
      {isCaregiverSearchModalOpened && (
        <SearchCaregiversModal
          onClose={toggleCaregiverSearchModal}
          translations={translations}
          routeName={ROUTE_NAME}
        />
      )}
      {isLocationSearchModalOpened && (
        <SearchLocationModal
          onClose={toggleLocationSearchModal}
          translations={translations}
          routeName={ROUTE_NAME}
        />
      )}
      {error && <ErrorMessage error={error} translations={translations} />}
      <LowerContainer>
        <ContentContainer isStyledPage>
          <InnerContainer>
            <PlacesContainer>
              <LocalCaregiversHeader>
                {translations.find_caregiver_location}
              </LocalCaregiversHeader>
              <div style={{ width: "50%" }}>
                <LocalCaregiverList>
                  {googlePlacesState.startData.child_places &&
                    googlePlacesState.startData.child_places.map((item) => {
                      const { name, place_id: placeId } = item.attributes;
                      return (
                        <li key={item?.id}>
                          <Alink to={`/${slugifyer(name)}/g/${placeId}`}>
                            {name}
                          </Alink>
                        </li>
                      );
                    })}
                </LocalCaregiverList>
              </div>
            </PlacesContainer>{" "}
          </InnerContainer>{" "}
          <InnerContainer style={{ marginBottom: "250px" }}>
            <Text style={{ marginBottom: "140px" }}>
              {translations.find_caregiver_by}{" "}
              <Alink to="/directory"> {translations.name} </Alink>{" "}
              {translations.or}{" "}
              <Alink to="/clinic-directory">{translations.clinic}</Alink>
              <br /> {translations.find_treatments_by}{" "}
              <Alink to="/treatment-directory">{translations.category}</Alink>
            </Text>
          </InnerContainer>
        </ContentContainer>
      </LowerContainer>
      <Footer showAnimalPharmacy translations={translations} />
    </Layout>
  );
};

export default StartPage;
