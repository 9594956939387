import { BaseService } from "services/base-service";

class DeclarationsService extends BaseService {
  constructor() {
    super();
    this.getDeclarationItemTemplate =
      this.getDeclarationItemTemplate.bind(this);
    this.postDeclarations = this.postDeclarations.bind(this);
  }

  async getDeclarationItemTemplate(bookingAttendeeExternalBindingId) {
    try {
      const response = await this.agent.get(
        "/muntra-declaration-item-templates",
        {
          params: {
            booking_attendee_external_binding_id:
              bookingAttendeeExternalBindingId,
            include: "declaration_item_category",
          },
        },
      );

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }

  async postDeclarations(data, bookingAttendeeExternalBindingId) {
    try {
      const response = await this.agent.post("/muntra-declarations", data, {
        params: {
          booking_attendee_external_binding_id:
            bookingAttendeeExternalBindingId,
        },
      });

      return Promise.resolve(response);
    } catch (error) {
      this.processError(error);
      return null;
    }
  }
}

const ServiceInstance = new DeclarationsService();

export default ServiceInstance;
export { ServiceInstance as DeclarationsService };
