import React from "react";

import defaultUserImage from "assets/images/defaultUserIcon.png";
import { proceduresActions } from "redux/procedures/actions";
import { rolesActions } from "redux/roles/actions";

import { Col } from "components/common.styled";
import {
  Person,
  SearchResultName,
  SearchResultText,
  StyledOptionContainer,
} from "components/header/index.styled";

import { slugifyer } from "./helper-functions";

const renderCaregiver = ({ item, caregiver, isActive }) => {
  return (
    <StyledOptionContainer active={isActive}>
      <Col xs={4}>
        <Person
          src={
            caregiver?.attributes?.original
              ? caregiver.attributes.original
              : defaultUserImage
          }
        />
      </Col>
      <Col xs={18}>
        <SearchResultName
          dangerouslySetInnerHTML={{ __html: item.attributes.name }}
        />
        {caregiver && (
          <SearchResultText>{`${caregiver.attributes?.role_name} ${
            caregiver.attributes?.city ? `∙ ${caregiver.attributes.city}` : ""
          }`}</SearchResultText>
        )}
      </Col>
    </StyledOptionContainer>
  );
};

const renderClinicOrRole = ({ item, isActive }) => {
  return (
    <StyledOptionContainer active={isActive}>
      <Col xs={24}>
        <SearchResultName
          dangerouslySetInnerHTML={{ __html: item.attributes.name }}
        />
      </Col>
    </StyledOptionContainer>
  );
};

export const arrangeCaregiversOptions = (searchResult, dispatch, history) => {
  const arr =
    searchResult.length > 0
      ? searchResult.sort((a, b) => {
          if (
            a?.search_result_caregiver?.attributes?.original !== null &&
            !b?.search_result_caregiver?.attributes?.original !== null
          )
            return -1;
          if (
            a?.search_result_caregiver?.attributes &&
            !a?.search_result_caregiver?.attributes?.original !== null &&
            !b?.search_result_caregiver?.attributes.original !== null
          )
            return 0;
          return 1;
        })
      : [];

  return arr.map((item) => {
    const caregiver = item.search_result_caregiver;
    const clinic = item.search_result_clinic;
    const procedure = item.search_result_roles_procedures;

    const result = () => {
      if (caregiver) {
        const value = item.attributes.name.replace(/<[^>]+>/g, "");
        return {
          value,
          link: `/${slugifyer(caregiver.attributes.role_name)}/${slugifyer(
            value,
          )}/p/${caregiver.attributes.caregiver_id}`,
          caregiver,
          action: (link) => history.push(link),
          label: renderCaregiver,
        };
      }
      if (clinic) {
        const value = item.attributes.name.replace(/<[^>]+>/g, "");
        return {
          value,
          link: `/${slugifyer(value)}/c/${clinic.attributes.clinic_id}`,
          clinic,
          action: (link) => history.push(link),
          label: renderClinicOrRole,
        };
      }
      if (procedure) {
        const value = item.attributes.name.replace(/<[^>]+>/g, "");
        return {
          value,
          link: `/${slugifyer(value)}/rp/${procedure.id}`,
          procedure,
          action: (link) => {
            dispatch(rolesActions.cleanUpRoles());
            dispatch(proceduresActions.setSelectedProcedure([], true));
            dispatch(rolesActions.setSelectedRole([]));
            dispatch(
              rolesActions.getRole({
                role: procedure.attributes.role_ids,
                procedure: procedure.attributes.procedure_ids,
              }),
            );
            history.push(link);
          },
          label: renderClinicOrRole,
        };
      }
    };

    return { item, ...result() };
  });
};
