import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { addHours, isBefore, parseISO } from "date-fns";
import * as PropTypes from "prop-types";

import defaultUserImage from "assets/images/defaultUserIcon.png";
import clinicPlaceholderImage from "assets/images/placeholder.jpeg";
import {
  formatDate,
  getCaregiverFullName,
  getItemFields,
} from "helpers/helper-functions";
import { selectTag } from "redux/app/selectors";

import RescheduleModal from "components/calendar/reschedule/reschedule-modal";
import { Avatar, StyledText } from "components/common.styled";

import {
  ClinicDefaultImage,
  Container,
  DateTimeText,
  ImageContainer,
  NameRoleHeader,
  RescheduleButton,
  RescheduleText,
  TextContainer,
} from "./index.styled";

const BookingCard = ({ bookings, isComing, item, translations }) => {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const locale = useSelector(selectTag);

  const WIDTH_BREAK = 740;

  const attendees = getItemFields(item.relationships, bookings);
  const caregiver = useMemo(
    () =>
      attendees?.booking_attendees?.find(
        (item) => item.relationships && item.relationships?.user.data !== null,
      ),
    [attendees?.booking_attendees],
  );

  const { location } = item.attributes || {};

  const date = useMemo(
    () =>
      `${formatDate(
        item.attributes.dtstart,
        "EEEE d LLLL uuuu 'kl.' H:mm",
        locale,
      )}-${formatDate(item.attributes.dtend, "H:mm")}`,
    [item.attributes, locale],
  );

  const role = caregiver?.user?.role?.attributes?.name;
  const fullName = getCaregiverFullName(caregiver);

  const startDateParsed = parseISO(item.attributes.dtstart);

  const reschedulePeriodInHours = useMemo(
    () =>
      bookings.find((booking) => {
        return (
          booking.type === "muntra_booking_policy" &&
          booking.id === item?.relationships?.booking_policy?.data?.id
        );
      })?.attributes?.rescheduling_lock_period_in_hours,
    [bookings, item?.relationships?.booking_policy?.data?.id],
  );

  const lockTime = addHours(new Date(), reschedulePeriodInHours || 0);
  const isDtStartBeforeLockTime = isBefore(startDateParsed, lockTime);

  return (
    <Container>
      <ImageContainer>
        {!attendees?.clinic?.default_clinic_image?.attributes && (
          <ClinicDefaultImage loading="lazy" src={clinicPlaceholderImage} />
        )}
        {attendees?.clinic?.default_clinic_image?.attributes?.original && (
          <ClinicDefaultImage
            loading="lazy"
            src={`${attendees.clinic.default_clinic_image.attributes.original}?w=600`}
            alt="Clinic default images"
          />
        )}
        {caregiver && (
          <div>
            <Avatar
              alt={fullName}
              loading="lazy"
              src={
                caregiver?.user?.default_user_image
                  ? caregiver.user.default_user_image.attributes.original
                  : defaultUserImage
              }
            />
          </div>
        )}
      </ImageContainer>
      <TextContainer isPast={!isComing}>
        <DateTimeText> {date} </DateTimeText>
        <NameRoleHeader>
          {role}
          {global.innerWidth < WIDTH_BREAK && <br />} {fullName}
        </NameRoleHeader>
        <StyledText> {location} </StyledText>
        {isModalOpened && (
          <RescheduleModal
            bookingData={item}
            caregiver={caregiver}
            onClose={() => setIsModalOpened(false)}
            onComplete={() => {
              const newHref = window.location.href.split("?")[0];
              window.location.href = newHref;
            }}
            translations={translations}
            authenticateUser
          />
        )}
      </TextContainer>
      {isComing &&
        item?.attributes?.reschedulable &&
        (isDtStartBeforeLockTime ? (
          <RescheduleButton secondary>
            <RescheduleText>
              {translations.bookings_cant_reschedule}
            </RescheduleText>
          </RescheduleButton>
        ) : (
          <RescheduleButton
            activeRescheduling
            onClick={() => setIsModalOpened(true)}
          >
            <RescheduleText>{translations.bookings_rebook}</RescheduleText>
          </RescheduleButton>
        ))}
      {!isComing ||
        (!item?.attributes?.reschedulable && (
          <RescheduleButton secondary>
            <RescheduleText>{translations.bookings_cant_rebook}</RescheduleText>
          </RescheduleButton>
        ))}
    </Container>
  );
};

BookingCard.propTypes = {
  bookings: PropTypes.array,
  isComing: PropTypes.bool,
  item: PropTypes.object,
  translations: PropTypes.object,
};

export default BookingCard;
