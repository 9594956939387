import styled, { css } from "styled-components";

import Button from "./common/button";
import ButtonDiv from "./common/button/button-div";
import Checkbox from "./common/checkbox";
import Pagination from "./common/pagination";
import Radio from "./common/radio";

import { Spinner } from "./common/spinner/index.styled";

const getWidthString = (span) => {
  if (!span) return;

  const width = (span / 24) * 100;
  return `width: ${width}%`;
};

export const Avatar = styled.img`
  margin-bottom: 10px;
  object-fit: cover;
  ${({ size }) => {
    if (size) {
      const borderRadius = size / 2;
      return css`
        width: ${size}px;
        height: ${size}px;
        border-radius: ${borderRadius}px;
      `;
    }
  }}
  ${({ border }) => {
    if (border) {
      return css`
        border: 3px solid #e1e1e4;
      `;
    }
  }}
`;

export const Image = styled.img`
  max-width: 500px;
  width: 100%;
  margin-bottom: 1em;
  align-self: center;
`;

export const Span = styled.span``;

export const Text = styled.p`
  color: #000000a6;
`;

export const BoldSpan = styled(Span)`
  font-weight: bold;
`;

export const BoldText = styled(Text)`
  font-weight: bold;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;

  ${({ alignitems }) => alignitems && `align-items: ${alignitems}`};

  ${({ xs }) => (xs ? getWidthString(xs) : "width: 100%")};

  @media (min-width: 576px) {
    ${({ sm }) => sm && getWidthString(sm)};
  }

  @media (min-width: 768px) {
    ${({ md }) => md && getWidthString(md)};
  }

  @media (min-width: 992px) {
    ${({ lg }) => lg && getWidthString(lg)};
  }

  @media (min-width: 1200px) {
    ${({ xl }) => xl && getWidthString(xl)};
  }
`;

export const Container = styled.div`
  width: 960px;
  margin: 2rem auto;
  padding: 2rem;
  font-family: Arial, sans-serif;
`;

export const ContentContainer = styled.div`
  width: 100%;
  min-height: 500px;
  padding-bottom: 50px;

  ${({ isStyledPage }) => {
    if (isStyledPage) {
      return css`
        @media (min-width: 250px) {
          padding-left: 20px !important;
          padding-right: 20px !important;
        }

        @media (min-width: 375px) {
          padding-left: 24px !important;
          padding-right: 24px !important;
        }

        @media (min-width: 744px) {
          padding-left: 40px !important;
          padding-right: 40px !important;
        }

        @media (min-width: 950px) {
          padding-left: 40px !important;
          padding-right: 40px !important;
        }

        @media (min-width: 1128px) {
          padding-left: 80px !important;
          padding-right: 80px !important;
        }

        @media (min-width: 1440px) {
          padding-left: 80px !important;
          padding-right: 80px !important;
        }
      `;
    }
  }}
`;

export const FourthHeading = styled.h4`
  color: #484848;
  font-weight: 700;
`;

export const Heading = styled.h1`
  color: #484848;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
`;

export const MainSpinner = styled(Spinner)`
  margin: 35vh auto 0;
  ${({ isSearch }) => isSearch && `margin: 20vh auto 0`};
  align-self: center;
`;

export const MobileSection = styled.div`
  @media (max-width: 993px) {
    padding: 32px 0;
  }
`;

export const NavbarButton = styled(Button)`
  border: 0;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  height: 100%;
  margin: 0;
  font-weight: 600;
  color: #484848;
  width: 128px;
`;

export const NavbarDivButton = styled(ButtonDiv)`
  padding: 10px;
  border: 0;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  height: 100%;
  margin: 0;
  font-weight: 600;
  color: #484848;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  ${({ alignitems }) => alignitems && `align-items: ${alignitems}`};
  ${({ noWrap }) => noWrap && `flex-wrap: nowrap`};
`;

export const SecondHeading = styled.h2`
  color: #484848;
  font-size: 18px;
  font-weight: 700;
  line-height: 22px;
`;

export const SmallText = styled(Text)`
  font-size: 10px;
`;

export const SpanLink = styled.a`
  color: #27272f !important;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledContent = styled.main`
  background: #fff;
  min-height: 280px;
  margin: 50px auto 260px auto;
  padding: 25px 50px;
  max-width: 1000px;
  box-shadow: 0 1px 5px 5px #00000008, 0 1px 2px 0px #0000003d;
  flex: none;
  width: 90%;

  // @media (max-width: 740px) {
  ${({ isStart }) => isStart && `padding: 15px 15px`};
  // }
  @media (max-width: 740px) {
    ${({ isPadding }) => isPadding && `padding: 25px 20px`};
  }
  @media (max-width: 740px) {
    ${({ isLessMargin }) =>
      isLessMargin && `margin: 20px auto 0px auto; !important`};
  }
`;

export const Content = styled(StyledContent)`
  margin: 50px auto 260px auto;
  max-width: 1400px;
`;

export const StyledPagination = styled(Pagination)`
  text-align: center;
  margin: 20px 0;
`;

export const StyledRow = styled(Row)`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

export const StyledCol = styled(Col)`
  margin: 0 5px 20px 5px;
`;

export const StyledText = styled(Text)`
  margin-bottom: 0px;
`;

export const StyledSpin = styled(Spinner)`
  margin-bottom: 40px;
`;

export const StyledFooter = styled.footer`
  height: 164px;
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;
  margin-top: auto;
  padding: 0;
  background: transparent;
  position: absolute;
  bottom: 0;
  width: 100%;

  @media (max-width: 993px) {
    bottom: 60px;
    ${({ withoutBottomMargin }) => withoutBottomMargin && "bottom: 0px"};
  }
`;

export const StyledHeader = styled.header`
  /* height: 81px; */
  background: #ffffff;
  display: flex;
  ${({ withoutBorder }) => withoutBorder && `border: none`};

  @media (max-width: 740px) {
    ${({ hideMenuOptions }) => hideMenuOptions && `height: 60px`};

    ${({ referralSourceEqualStatus }) =>
      referralSourceEqualStatus && `height: 0`};
  }
  @media (min-width: 250px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: 375px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media (min-width: 744px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (min-width: 950px) {
    padding-left: 40px;
    padding-right: 40px;
  }

  @media (min-width: 1128px) {
    padding-left: 80px;
    padding-right: 80px;
  }

  @media (min-width: 1440px) {
    padding: 0 7% !important;
  }
`;

export const InputContainer = styled.div`
  border: 1px solid #00000036;
  border-radius: 2px;
  padding: 2px 10px;
  display: flex;
  transition: all 0.3s;
  width: 100%;
  height: 45px;

  &:hover {
    border: 1px solid #1890ff;
    & svg {
      fill: #1890ff;
    }
  }

  &:focus-within {
    box-shadow: 0px 0px 1px 3px rgba(24, 143, 255, 0.31);
    border: 1px solid #1890ff;
  }

  & input {
    border: 0;
    text-overflow: ellipsis;
    width: 100%;
    line-height: 20px;

    &:focus {
      outline: 0;
    }
  }
`;

export const StyledInput = styled.input`
  font-size: 15px;
  padding: 14px 0;
`;

export const StyledLayout = styled.section`
  background-color: white;
  display: flex;
  flex: auto;
  flex-direction: column;

  & a {
    text-decoration: none;
    color: rgb(24, 144, 255);
    cursor: pointer;
  }

  & .link {
    text-decoration: none;
    color: #484848;
    cursor: pointer;
  }

  & .ReactCollapse--collapse {
    transition: height 400ms;
  }

  position: relative;
  min-height: 100vh;
`;

export const Layout = styled(StyledLayout)``;

export const ThirdHeading = styled.h3`
  color: #484848;
  font-weight: 700;
`;

export const TransitionStep = styled.div`
  text-align: center;
  transition: 150ms opacity, 150ms transform;
  opacity: 1;
  overflow-x: hidden;
  overflow-y: hidden;

  &.next {
    transform: translateX(150%);
    opacity: 0;
    position: absolute;
  }

  &.previous {
    transform: translateX(-150%);
    opacity: 0;
    position: absolute;
  }
`;

export const VerticalRadio = styled(Radio)`
  display: block;
  height: 25px;
  line-height: 25px;
`;

export const VerticalCheckbox = styled(Checkbox)`
  display: block;
  height: 25px;
  line-height: 25px;
  margin-top: 10px;
`;
