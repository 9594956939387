import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import * as PropTypes from "prop-types";
import {
  FakeSearchDiv,
  FakeSearchSpan,
} from "style/global-styled-components.styled";

import MuntraLogo from "assets/images/muntraLogo.png";
import SearchSvg from "assets/svg-components/Search";
import SearchButtonSvg from "assets/svg-components/SearchButton";
import DocumentIcon from "assets/svg-components/user-menu/DocumentIcon";
import HeartIcon from "assets/svg-components/user-menu/HeartIcon";
import PlusIcon from "assets/svg-components/user-menu/PlusIcon";
import UserIcon from "assets/svg-components/user-menu/UserIcon";
import { arrangeCaregiversOptions } from "helpers/arrange-caregivers-options";
import { arrangePlacesOptions } from "helpers/arrange-places-options";
import { useDebounce } from "hooks/use-debounce";
import { useQuery } from "hooks/use-query";
import { useSignInValidation } from "hooks/use-sign-in-validation";
import { authActions } from "redux/auth/actions";
import { caregiversActions } from "redux/caregivers/actions";
import { googlePlacesActions } from "redux/google-places/actions";

import AuthModal from "../auth/auth-modal";
import RegisterModal from "../auth/register-modal";
import BookingModal from "../calendar/booking-modal/booking-modal";
import { Row, StyledHeader } from "../common.styled";
import { NavbarButton } from "../common/button";
import Dropdown from "../common/dropdown";
import BottomMenu from "./bottom-menu";
import SearchModal from "./search-modal";

import {
  ButtonWrapper,
  DesktopMenu,
  LeftSearch,
  Logo,
  MainContainer,
  MainHeaderDiv,
  RightSearch,
  SearchRow,
  StyledButton,
} from "./index.styled";

const Header = ({
  addPaddingBottom,
  bottomBorder,
  children,
  hideSearchBarOnScroll,
  isModal,
  isSearch,
  places,
  referralSourceEqualStatus,
  showSearchBar,
  translations,
  withoutBorder,
}) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const history = useHistory();
  const query = useQuery();
  const { caregiver_id: caregiverId, clinic_id: clinicId } = useParams();

  const bookingIsTentative = query.get("booking_is_tentative");
  const defaultProcedureId = query.get("defaultProcedureId");
  const defaultProcedureTitle = query.get("defaultProcedureTitle");
  const dtend = query.get("dtend");
  const dtstart = query.get("dtstart");

  const authState = useSelector((state) => state.auth);
  const caregiversState = useSelector((state) => state.caregivers);
  const placesState = useSelector((state) => state.googlePlaces);
  const rolesState = useSelector((state) => state.roles);

  const [isFakeSearchModalOpened, setIsFakeSearchModalOpened] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isRegisterModalOpened, setIsRegisterModalOpened] = useState(false);

  const pageName = useMemo(() => {
    if (pathname.includes("clinics")) return "clinics";
    if (pathname.includes("caregivers")) return "caregivers";
    if (pathname.includes("clinic-directory")) return "clinic-directory";
    if (pathname.includes("clinic")) return "clinic";
    if (pathname.includes("caregiver")) return "caregiver";
    if (pathname.includes("place")) return "place";
    if (pathname.includes("role")) return "role";
    return pathname.split("/")[1];
  }, [pathname]);

  const attributes = useMemo(
    () => ({
      dtend,
      dtstart,
    }),
    [dtend, dtstart],
  );

  const selectedSlot = useMemo(() => ({ attributes }), [attributes]);

  const SEARCH_BAR_LOWER_MARGIN = useMemo(
    () => (addPaddingBottom ? "10px" : ""),
    [addPaddingBottom],
  );

  const LOGO_HEIGHT = "60px";
  const SEARCH_BAR_HEIGHT = "40px";
  const SEARCH_BAR_UPPER_MARGIN = "10px";

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [optionsCaregiver, setOptionsCaregiver] = useState([]);
  const [optionsPlaces, setOptionsPlaces] = useState([]);
  const [selectedProcedures, setSelectedProcedures] = useState([]);
  const [scrollLogoHeight, setScrollLogoHeight] = useState(LOGO_HEIGHT);
  const [scrollSearchBarHeight, setScrollSearchBarHeight] =
    useState(SEARCH_BAR_HEIGHT);
  const [searchDisplay, setSearchDisplay] = useState("block");
  const [scrollSearchUpperBarMargin, setScrollSearchUpperBarMargin] =
    useState("0px");
  const [scrollSearchLowerBarMargin, setScrollSearchLowerBarMargin] = useState(
    SEARCH_BAR_LOWER_MARGIN,
  );
  const [searchBarOpacity, setSearchBarOpacity] = useState(1);
  const [scrollTimer, setScrollTimer] = useState(new Date());

  const handleChangeCaregiversSearch = useCallback(
    (value) => {
      dispatch(caregiversActions.searchCaregiversChange(value));
    },
    [dispatch],
  );

  const handleChangePlacesSearch = useCallback(
    (value) => {
      dispatch(googlePlacesActions.searchGooglePlacesChange(value));
    },
    [dispatch],
  );

  const toggleModal = useCallback(() => setIsModalOpened((prev) => !prev), []);

  const toggleRegisterModal = useCallback(
    () => setIsRegisterModalOpened((prev) => !prev),
    [],
  );

  const toggleFakeSearchModal = useCallback(
    () => setIsFakeSearchModalOpened(!isFakeSearchModalOpened),
    [isFakeSearchModalOpened],
  );

  const setUnScrolledStyle = useCallback(() => {
    setScrollLogoHeight(LOGO_HEIGHT);
    setScrollSearchUpperBarMargin("0px");
    if (hideSearchBarOnScroll) {
      setScrollSearchBarHeight(SEARCH_BAR_HEIGHT);
      setSearchBarOpacity(1);
      setSearchDisplay("block");
      setScrollSearchLowerBarMargin(SEARCH_BAR_LOWER_MARGIN);
    }
  }, [SEARCH_BAR_LOWER_MARGIN, hideSearchBarOnScroll]);

  const setScrolledStyle = useCallback(() => {
    setScrollLogoHeight(0);
    setScrollSearchUpperBarMargin(SEARCH_BAR_UPPER_MARGIN);
    if (hideSearchBarOnScroll) {
      setScrollSearchBarHeight("0px");
      setSearchBarOpacity(0);
      setSearchDisplay("none");
      setScrollSearchLowerBarMargin("0px");
    }
  }, [hideSearchBarOnScroll]);

  const handleScroll = useCallback(() => {
    if (global.innerWidth >= 994) {
      if (scrollLogoHeight !== LOGO_HEIGHT) {
        setUnScrolledStyle();
      }
    } else {
      const timeSinceScrollUpdate = Math.abs(
        (new Date().getTime() - scrollTimer.getTime()) / 1000,
      );
      if (
        document.documentElement.scrollTop === 0 &&
        timeSinceScrollUpdate > 0.2
      ) {
        setUnScrolledStyle();
        setScrollTimer(new Date());
      } else {
        setScrolledStyle();
      }
    }
  }, [scrollLogoHeight, scrollTimer, setScrolledStyle, setUnScrolledStyle]);

  const searchCaregiversCb = useCallback(() => {
    if (caregiversState?.search?.length >= 3 && showSearchBar) {
      dispatch(
        caregiversActions.searchCaregivers(caregiversState?.search, pageName),
      );
    }
  }, [caregiversState?.search, dispatch, pageName, showSearchBar]);

  const searchGooglePlacesCb = useCallback(() => {
    if (placesState?.search?.search?.length >= 3 && showSearchBar) {
      dispatch(
        googlePlacesActions.searchGooglePlaces(
          placesState?.search?.search,
          placesState?.search?.isAutocomplete,
          pageName,
        ),
      );
    }
  }, [
    placesState?.search?.search,
    placesState?.search?.isAutocomplete,
    showSearchBar,
    dispatch,
    pageName,
  ]);

  useDebounce(searchCaregiversCb, [], 400);
  useDebounce(searchGooglePlacesCb, [], 400);

  useSignInValidation();

  useEffect(() => {
    const procedures = rolesState?.currentRole?.procedure?.map((item) => ({
      id: item.id,
      title: item.attributes.name,
    }));

    if (procedures) setSelectedProcedures(procedures);
  }, [rolesState.currentRole]);

  useEffect(() => {
    if (
      authState?.authUserPersonalId?.data &&
      caregiverId &&
      clinicId &&
      dtend &&
      dtstart
    ) {
      setIsOpenModal(true);
    }
  }, [
    authState?.authUserPersonalId?.data,
    caregiverId,
    clinicId,
    dtend,
    dtstart,
  ]);

  useEffect(() => {
    if (caregiversState?.searchResult && showSearchBar)
      setOptionsCaregiver(
        arrangeCaregiversOptions(
          caregiversState.searchResult,
          dispatch,
          history,
        ),
      );
  }, [caregiversState?.searchResult, dispatch, history, showSearchBar]);

  useEffect(() => {
    if (placesState?.searchResult?.data && showSearchBar)
      setOptionsPlaces(
        arrangePlacesOptions(placesState?.searchResult, dispatch, history),
      );
  }, [dispatch, history, placesState?.searchResult, showSearchBar]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    const isAutocomplete = true;
    if (places?.info?.attributes) {
      dispatch(
        googlePlacesActions.searchGooglePlacesChange(
          places.info.attributes.name,
          isAutocomplete,
        ),
      );
    } else if (places?.attributes) {
      dispatch(
        googlePlacesActions.searchGooglePlacesChange(
          places.attributes.name,
          isAutocomplete,
        ),
      );
    }
  }, [dispatch, places]);

  const loginOptions = useMemo(
    () => [
      {
        title: `${translations.header_edit_profile}`,
        action: () => history.push("/patient-profile", "/patient-profile"),
      },
      {
        title: `${translations.header_logout}`,
        action: () => dispatch(authActions.userSignOut()),
      },
    ],
    [dispatch, history, translations],
  );

  const menuOptions = useMemo(() => {
    if (authState?.authUserPersonalId?.data && global.window.innerWidth >= 994)
      return [
        {
          id: "cd55119f-3719-49d8-9209-a34774685274",
          title: `${translations.header_invoices}`,
          action: () => history.push("/invoices", "/invoices"),
        },
        {
          id: "4fe0d681-3647-43e0-8e77-5da80cf047f3",
          title: `${translations.header_treatments}`,
          action: () => history.push("/bookings", "/bookings"),
        },
        {
          id: "ff0dd671-3aaf-4940-9c3b-f030bff1e829",
          title: (
            <Dropdown
              isIncluded
              title={`${
                authState.authUserPersonalId?.data?.attributes?.first_name || ""
              }`}
              options={loginOptions}
            />
          ),
        },
      ];

    if (authState?.authUserPersonalId?.data && global.window.innerWidth < 994)
      return [
        {
          id: "b39cac1f-164e-4192-b8d7-bf897e376177",
          title: `${translations.header_explore}`,
          action: () => history.push("/start", "/start"),
          icon: <HeartIcon />,
          link: "/start",
        },
        {
          id: "c9edbbe0-a3a8-46c6-a919-8f9952e04957",
          title: `${translations.header_bookings}`,
          action: () => history.push("/bookings", "/bookings"),
          icon: <PlusIcon />,
          link: "/bookings",
        },
        {
          id: "fcdb73f6-214c-42db-a249-254ed2b915d0",
          title: `${translations.header_invoices}`,
          action: () => history.push("/invoices", "/invoices"),
          icon: <DocumentIcon />,
          link: "/invoices",
        },
        {
          id: "81063936-9ce5-4f1e-9a26-d81763dc7b4c",
          title: (
            <Dropdown
              isIncludedMobile
              title={translations.header_profile}
              options={loginOptions}
            />
          ),
          link: "/patient-profile",
        },
      ];

    if (global.innerWidth < 994)
      return [
        {
          id: "9842ce29-db03-40ab-b053-0e9da135d6c8",
          title: `${translations.header_explore}`,
          action: () => history.push("/start", "/start"),
          icon: <HeartIcon />,
          link: "/start",
        },
        {
          id: "7ee1bcb8-317f-4552-9f01-e65719f229ab",
          title: `${translations.header_login}`,
          action: toggleModal,
          icon: <UserIcon />,
        },
      ];

    if (global.innerWidth >= 994)
      return [
        {
          id: "caaf34b5-759b-4a9b-ad65-bdad64699925",
          title: (
            <a className="link" href="https://about.muntra.se">
              {translations.header_for_caregivers}
            </a>
          ),
        },
        {
          id: "92ce72fe-6f25-4a2d-8b47-7936f03fde98",
          title: `${translations.header_register_yourself}`,
          action: toggleRegisterModal,
        },
        {
          id: "08019d3f-a99a-4ba2-91ef-7e1842cb19f6",
          title: `${translations.header_login}`,
          action: toggleModal,
        },
      ];
    return [];
  }, [
    authState.authUserPersonalId?.data,
    loginOptions,
    history,
    toggleModal,
    toggleRegisterModal,
    translations,
  ]);

  const renderHeaderSearchBar = useCallback(() => {
    if (showSearchBar) {
      return (
        <SearchRow>
          {isFakeSearchModalOpened && (
            <SearchModal
              onClose={toggleFakeSearchModal}
              translations={translations}
            />
          )}
          <SearchRow className="HideIfDesktop">
            <span
              style={{
                marginTop: scrollSearchUpperBarMargin,
                display: searchDisplay,
              }}
            >
              <FakeSearchDiv
                className="MobileHeaderSearchModalButton"
                onClick={toggleFakeSearchModal}
                style={{
                  height: scrollSearchBarHeight,
                  opacity: searchBarOpacity,
                  transition: "0.2",
                  overflow: "hidden",
                }}
              >
                <div className="SVGContainer">
                  <SearchSvg />
                </div>
                <FakeSearchSpan>
                  {`${translations.header_search_care_or_place}`}
                </FakeSearchSpan>
              </FakeSearchDiv>
            </span>
          </SearchRow>
          <LeftSearch
            className="HideIfMobile"
            handleSearchChange={handleChangeCaregiversSearch}
            isCaregiverSearchBar
            isLoading={caregiversState.searchLoading}
            leftSearch
            options={optionsCaregiver}
            placeholder={translations.header_search_care_placeholder}
            validText={translations.header_search_care_validText}
            value={caregiversState.search || ""}
          />
          <RightSearch
            className="HideIfMobile"
            handleSearchChange={handleChangePlacesSearch}
            isLoading={placesState.searchLoading}
            options={optionsPlaces}
            placeholder={
              translations.header_search_place_validText_and_placeholder
            }
            validText={
              translations.header_search_place_validText_and_placeholder
            }
            value={placesState.search?.search || ""}
          />
          <StyledButton
            circle
            onClick={() => history.push("/search")}
            secondary
            style={{
              WebkitAppearance: "none",
              padding: "0 10px",
            }}
          >
            <SearchButtonSvg style={{ margin: "0px !important" }} />
          </StyledButton>
        </SearchRow>
      );
    }
  }, [
    caregiversState.search,
    caregiversState.searchLoading,
    handleChangeCaregiversSearch,
    handleChangePlacesSearch,
    isFakeSearchModalOpened,
    history,
    optionsCaregiver,
    optionsPlaces,
    placesState.search?.search,
    placesState.searchLoading,
    scrollSearchBarHeight,
    scrollSearchUpperBarMargin,
    searchBarOpacity,
    searchDisplay,
    showSearchBar,
    toggleFakeSearchModal,
    translations,
  ]);

  return (
    <div
      className={isModal ? "" : "StickyMobileHeader"}
      style={{
        borderBottom: bottomBorder ? "1px solid #e4e4e4" : "",
      }}
    >
      <MainHeaderDiv>
        {!referralSourceEqualStatus && (
          <StyledHeader
            className="flexJustCenter"
            referralSourceEqualStatus={referralSourceEqualStatus}
            style={{ paddingBottom: scrollSearchLowerBarMargin }}
            withoutBorder={withoutBorder}
          >
            <div className="innerHeaderContainer" style={{ width: "100%" }}>
              <MainContainer
                className="FlexColMobile"
                style={{ alignItems: isModal ? "center" : "" }}
              >
                <Link
                  style={{
                    display: "flex",
                    height: scrollLogoHeight,
                    transition: "0.2s",
                  }}
                  to="/start"
                >
                  <Logo
                    alt="logo"
                    className="Logo"
                    src={MuntraLogo}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100%",
                      width: "auto",
                      objectFit: "contain",
                    }}
                  />
                </Link>
                {renderHeaderSearchBar()}
              </MainContainer>
              <DesktopMenu>
                {menuOptions.map((item) => (
                  <NavbarButton
                    key={item.id}
                    secondary
                    onClick={() => item?.action?.()}
                  >
                    <ButtonWrapper>{item.title}</ButtonWrapper>
                  </NavbarButton>
                ))}
              </DesktopMenu>
            </div>
          </StyledHeader>
        )}
        {children && <Row>{children}</Row>}
        {isRegisterModalOpened && (
          <RegisterModal
            onClose={toggleRegisterModal}
            translations={translations}
          />
        )}
        {isModalOpened && (
          <AuthModal onClose={toggleModal} translations={translations} />
        )}
        {isOpenModal && (
          <BookingModal
            bookingIsTentative={bookingIsTentative}
            bookingRequiresTwoFactorAuthentication
            clinicId={clinicId}
            defaultProcedure={selectedProcedures}
            defaultProcedureId={defaultProcedureId}
            defaultProcedureTitle={defaultProcedureTitle}
            isSearch={isSearch}
            modalStep={3}
            onClose={() => setIsOpenModal(false)}
            selectedSlot={selectedSlot}
            slotsId={caregiverId}
            translations={translations}
          />
        )}
        {global.innerWidth < 994 && !isModal && (
          <BottomMenu menuOptions={menuOptions} translations={translations} />
        )}
      </MainHeaderDiv>
    </div>
  );
};

Header.propTypes = {
  bottomBorder: PropTypes.bool,
  children: PropTypes.node,
  addPaddingBottom: PropTypes.bool,
  isModal: PropTypes.bool,
  isSearch: PropTypes.bool,
  showSearchBar: PropTypes.bool,
  places: PropTypes.object,
  referralSourceEqualStatus: PropTypes.bool,
  translations: PropTypes.object,
  withoutBorder: PropTypes.bool,
  hideSearchBarOnScroll: PropTypes.bool,
};

Header.defaultProps = {
  addPaddingBottom: false,
  bottomBorder: true,
  isModal: false,
};

export default memo(Header);
