import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import * as PropTypes from "prop-types";
import { DeclarationsService } from "services/declarations";

import CloseIcon from "assets/svg-components/CloseIcon";
import LeftArrowSVG from "assets/svg-components/LeftArrow";
import {
  formatHours,
  getItemFields,
  mobileValidator,
} from "helpers/helper-functions";
import { selectTag } from "redux/app/selectors";

import CheckBoxesAndTextAreaContent from "./check-boxes-and-text-area-content";
import HealthDeclarationHeader from "./health-declaration-header";
import HealthDeclarationPreview from "./health-declaration-preview";
import HealthStateStep from "./health-state-step";
import NextButton from "./next-button";
import TwoAlternativesButtons from "./two-alternatives-buttons";

const HealthDeclaration = ({
  bookingExternalBindingId,
  onClose,
  translations,
}) => {
  const { selectedSlot, caregiverDataForBooking = {} } = useSelector(
    (state) => state.caregivers,
  );
  const locale = useSelector(selectTag);
  const { clinic } = caregiverDataForBooking;
  const { slot } = selectedSlot;

  const [declarationItems, setDeclarationItems] = useState([]);
  const [itemsCategories, setItemsCategories] = useState([]);
  const [medicationsFreeText, setMedicationsFreeText] = useState("");
  const [patientHealthEvaluation, setPatientHealthEvaluation] = useState("");
  const [specialNotes, setSpecialNotes] = useState({});
  const [step, setStep] = useState(1);
  const [takesMedicine, setTakesMedicine] = useState(false);

  const specialNotesText = useMemo(() => {
    return Object.keys(specialNotes)
      ?.map((key) =>
        specialNotes[key] ? `${key}: ${specialNotes[key]}\n\n` : "",
      )
      ?.join("");
  }, [specialNotes]);

  const isMobile = mobileValidator.test(window.navigator.userAgent);

  const getBackButtonClassName = useCallback(() => {
    if (step === 1 || step === itemsCategories.length + 4) {
      return "transparentSVG";
    }
    return "";
  }, [itemsCategories.length, step]);

  const getClassName = useCallback(
    (i) => {
      if (step === i) return "";
      return step > i ? "previous" : "next";
    },
    [step],
  );

  const scrollToTop = useCallback(() => {
    document.getElementById("healthDeclarationTop").scrollIntoView();
  }, []);

  const getDeclarations = useCallback(async () => {
    const result = await DeclarationsService.getDeclarationItemTemplate(
      bookingExternalBindingId,
    );

    if (result?.data) {
      const declarationTemplates = result.data.data.map((item) => {
        const { relationships, ...rest } = item;
        const itemFieldsResult = getItemFields(
          relationships,
          result.data.included,
        );
        return { ...itemFieldsResult, ...rest };
      });

      const itemCategories = result.data.included
        .filter((item) => item.type === "declaration_item_category")
        .sort((a, b) => a.id - b.id);

      setDeclarationItems(declarationTemplates);
      setItemsCategories(itemCategories);
    }
  }, [bookingExternalBindingId]);

  useEffect(() => {
    getDeclarations();
  }, [getDeclarations]);

  const stepSetter = useCallback(async () => {
    if (step === itemsCategories.length + 3) {
      const healthDeclarationOptions = {
        data: {
          type: "muntra_declaration",
          attributes: {
            medications_free_text: medicationsFreeText,
            patient_health_evaluation: patientHealthEvaluation,
            special_notes: specialNotesText,
          },
          relationships: {
            declaration_items: {
              data: declarationItems.map((item) => ({
                id: item.id,
                type: "muntra_declaration_item",
                attributes: {
                  value: item.attributes.value,
                },
                relationships: {
                  declaration_item_template: {
                    data: {
                      id: item.id,
                      type: item.type,
                    },
                  },
                },
              })),
            },
          },
        },
      };

      const result = await DeclarationsService.postDeclarations(
        healthDeclarationOptions,
        bookingExternalBindingId,
      );

      if (result) {
        setStep(step + 1);
        scrollToTop();
      }
    } else {
      setStep(step + 1);
      scrollToTop();
    }
  }, [
    bookingExternalBindingId,
    declarationItems,
    itemsCategories.length,
    medicationsFreeText,
    patientHealthEvaluation,
    scrollToTop,
    specialNotesText,
    step,
  ]);

  const onGoBackStep = useCallback(() => {
    if (step > 1) {
      setStep(step - 1);
      scrollToTop();
    }
  }, [scrollToTop, step]);

  return (
    <>
      <div
        id="healthDeclarationTop"
        style={{ position: "fixed", top: "0px" }}
      />

      <div
        className={`flexSpaceBetween StickyMobileHeader ${
          isMobile ? "bottomBorderGrey" : ""
        }`}
        style={{ background: "white" }}
      >
        <button
          className="ghostButton"
          id="backButton"
          onClick={onGoBackStep}
          style={{ marginLeft: "10px", cursor: "pointer" }}
        >
          <LeftArrowSVG classProp={getBackButtonClassName()} />
        </button>
        <button
          className="ghostButton"
          onClick={onClose}
          style={{ marginRight: "10px" }}
        >
          <CloseIcon />
        </button>
      </div>

      <div
        className="FlexCol"
        style={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className={`transitionStep ${getClassName(1)}`}>
          <div>
            {!takesMedicine && (
              <>
                <HealthDeclarationHeader
                  headerTitle={translations.hn_do_you_take_meds}
                />
                <TwoAlternativesButtons
                  leftButtonText={translations.hn_yes}
                  leftOnClickValue
                  onClickLeft={setTakesMedicine}
                  onClickRight={stepSetter}
                  rightButtonText={translations.hn_no}
                />
              </>
            )}
            {takesMedicine && (
              <>
                <CheckBoxesAndTextAreaContent
                  header={translations.medication_heading}
                  otherText={medicationsFreeText}
                  setOtherText={setMedicationsFreeText}
                  showCheckBoxes={false}
                  textAreaLabel={translations.hn_med_label}
                  textAreaPlaceholder="Namn och typ"
                />
                <NextButton
                  buttonText={translations.hn_next}
                  disabled={medicationsFreeText === ""}
                  distanceFromScreenBottom="25px"
                  positionFixed
                  stepSetter={stepSetter}
                />
              </>
            )}
          </div>
        </div>
        {itemsCategories.map((category, index) => {
          const checkBoxPropertiesArray = declarationItems.filter(
            (declarationItem) =>
              declarationItem.declaration_item_category.id === category.id,
          );

          return (
            <div
              key={category.attributes?.label}
              className={`transitionStep ${getClassName(index + 2)}`}
            >
              <CheckBoxesAndTextAreaContent
                checkBoxPropertiesArray={checkBoxPropertiesArray}
                header={category.attributes?.label}
                healthDeclarationTextProperty="medications_free_text"
                otherText={specialNotes[category.attributes?.label]}
                setDeclarationItems={setDeclarationItems}
                setOtherText={(text) => {
                  setSpecialNotes((prevNotes) => ({
                    ...prevNotes,
                    [category.attributes.label]: text || "",
                  }));
                }}
                showCheckBoxes
                textAreaLabel={translations.hn_free_text}
                textAreaPlaceholder=""
              />
              <NextButton
                buttonText={translations.hn_next}
                stepSetter={stepSetter}
              />
            </div>
          );
        })}
        <div
          className={`transitionStep FlexColJustCenter90vh ${getClassName(
            itemsCategories.length + 2,
          )}`}
          style={{ height: "70vh" }}
        >
          <HealthStateStep
            setPatientHealthEvaluation={setPatientHealthEvaluation}
            stepSetter={stepSetter}
            translations={translations}
          />
        </div>
        <div
          className={`transitionStep ${getClassName(
            itemsCategories.length + 3,
          )}`}
        >
          <div>
            <HealthDeclarationPreview
              declarationItems={declarationItems}
              itemsCategories={itemsCategories}
              patientHealthEvaluation={patientHealthEvaluation}
              medicationsFreeText={medicationsFreeText}
              specialNotesText={specialNotesText}
              translations={translations}
            />
            <NextButton
              buttonText={translations.hn_finish_and_send}
              placeAtScreenBottom="25px"
              stepSetter={stepSetter}
            />
          </div>
        </div>
        <div
          className={`transitionStep ${getClassName(
            itemsCategories.length + 4,
          )}`}
        >
          <div>
            <HealthDeclarationHeader
              headerHeight="70vh"
              headerTitle={translations.hn_declaration_done}
              isMobile={isMobile}
              showCelebrationIcon
              subHeader={`${
                translations.health_declaration_subheading_welcome
              } ${clinic.attributes.clinic_name} \n ${
                translations.health_declaration_subheading_at
              } ${formatHours(slot.attributes.dtstart, "H:mm")}-${formatHours(
                slot.attributes.dtend,
                "H:mm EEEE d MMMM",
                locale,
              )}`}
            />
            <NextButton
              buttonText={translations.hn_close}
              placeAtScreenBottom="25px"
              stepSetter={onClose}
            />
          </div>
        </div>
      </div>
    </>
  );
};

HealthDeclaration.propTypes = {
  bookingExternalBindingId: PropTypes.string,
  onClose: PropTypes.func,
  translations: PropTypes.object,
};

export default memo(HealthDeclaration);
