import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useHistory, useParams } from "react-router";
import { DeclarationsService } from "services/declarations";

import CloseIcon from "assets/svg-components/CloseIcon";
import LeftArrowSVG from "assets/svg-components/LeftArrow";
import { getItemFields, mobileValidator } from "helpers/helper-functions";
import { useTranslations } from "hooks/use-translations";

import Header from "components/header";
import CheckBoxesAndTextAreaContent from "components/health-declaration/check-boxes-and-text-area-content";
import HealthDeclarationPreview from "components/health-declaration/health-declaration-preview";
import HealthStateStep from "components/health-declaration/health-state-step";
import NextButton from "components/health-declaration/next-button";
import TwoAlternativesButtons from "components/health-declaration/two-alternatives-buttons";

import HealthDeclarationPageHeader from "../components/health-declaration-header";

const HealthDeclarationPage = () => {
  const history = useHistory();
  const { booking_external_binding_id: bookingExternalBindingId } = useParams();

  const routeName = "h.n.[external_id]";
  const translations = useTranslations(routeName);

  const [declarationItems, setDeclarationItems] = useState([]);
  const [itemsCategories, setItemsCategories] = useState([]);
  const [medicationsFreeText, setMedicationsFreeText] = useState("");
  const [patientHealthEvaluation, setPatientHealthEvaluation] = useState("");
  const [specialNotes, setSpecialNotes] = useState({});
  const [step, setStep] = useState(1);
  const [takesMedicine, setTakesMedicine] = useState(false);

  const specialNotesText = useMemo(() => {
    return Object.keys(specialNotes)
      ?.map((key) =>
        specialNotes[key] ? `${key}: ${specialNotes[key]}\n\n` : "",
      )
      ?.join("");
  }, [specialNotes]);

  const isMobile = mobileValidator.test(window.navigator.userAgent);

  const getBackButtonClassName = () => {
    if (step === 1 || step === itemsCategories.length + 4) {
      return "transparentSVG";
    }
    return "";
  };

  const getClassName = (i) => {
    if (step === i) return "";
    return step > i ? "previous" : "next";
  };

  const scrollToTop = useCallback(() => {
    window.scrollY.toFixed(0);
  }, []);

  const getDeclarations = useCallback(async () => {
    const result = await DeclarationsService.getDeclarationItemTemplate(
      bookingExternalBindingId,
    );

    if (result?.data) {
      const declarationTemplates = result.data.data.map((item) => {
        const { relationships, attributes, ...rest } = item;
        attributes.value = false;

        const itemFieldsResult = getItemFields(
          relationships,
          result.data.included,
        );
        return { attributes, ...itemFieldsResult, ...rest };
      });

      const itemCategories = result.data.included
        .filter((item) => item.type === "declaration_item_category")
        .sort((a, b) => a.id - b.id);

      setDeclarationItems(declarationTemplates);
      setItemsCategories(itemCategories);
    }
  }, [bookingExternalBindingId]);

  useEffect(() => {
    getDeclarations();
  }, [getDeclarations]);

  useEffect(() => {
    scrollToTop();
  }, [scrollToTop, step]);

  const stepSetter = useCallback(async () => {
    if (step === itemsCategories.length + 3) {
      const healthDeclarationOptions = {
        data: {
          type: "muntra_declaration",
          attributes: {
            medications_free_text: medicationsFreeText,
            patient_health_evaluation: patientHealthEvaluation,
            special_notes: specialNotesText,
          },
          relationships: {
            declaration_items: {
              data: declarationItems.map((item) => ({
                type: "muntra_declaration_item",
                attributes: {
                  value: item.attributes.value,
                },
                relationships: {
                  declaration_item_template: {
                    data: {
                      id: item.id,
                      type: item.type,
                    },
                  },
                },
              })),
            },
          },
        },
      };

      const result = await DeclarationsService.postDeclarations(
        healthDeclarationOptions,
        bookingExternalBindingId,
      );

      if (result) {
        setStep(step + 1);
        scrollToTop();
      }
    } else {
      setStep(step + 1);
      scrollToTop();
    }
  }, [
    bookingExternalBindingId,
    declarationItems,
    itemsCategories.length,
    medicationsFreeText,
    patientHealthEvaluation,
    scrollToTop,
    specialNotesText,
    step,
  ]);

  const onGoBackStep = () => {
    if (step > 1) {
      setStep(step - 1);
      scrollToTop();
    }
  };

  return (
    <>
      <Helmet>
        <title>{`${translations.hn_submit_health_dec_digitally}`}</title>
      </Helmet>
      <section className="layout">
        <div
          className={`flexSpaceBetween StickyMobileHeader bottomBorderGrey
        }`}
          style={{ background: "white" }}
        >
          <button
            className="ghostButton"
            id="backButton"
            onClick={onGoBackStep}
            style={{ marginLeft: "20px", cursor: "pointer" }}
          >
            <LeftArrowSVG classProp={getBackButtonClassName()} />
          </button>
          <Header
            centeredLogo
            flexWidth100
            hideMenuOptions
            isModal
            isStartPage
            bottomBorder={false}
            translations={translations}
          />
          <button
            className="ghostButton"
            onClick={() => history.push("/")}
            style={{ marginRight: "20px" }}
          >
            <CloseIcon />
          </button>
        </div>

        <div
          className="FlexCol healthDeclarationLayout"
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className={`transitionStep ${getClassName(1)}`}>
            {step === 1 && (
              <div>
                {!takesMedicine && (
                  <>
                    <HealthDeclarationPageHeader
                      headerTitle={translations.hn_do_you_take_meds}
                    />
                    <TwoAlternativesButtons
                      containerClass=""
                      leftButtonText={translations.hn_yes}
                      leftOnClickValue
                      onClickLeft={setTakesMedicine}
                      onClickRight={stepSetter}
                      rightButtonText={translations.hn_no}
                    />
                  </>
                )}
                {takesMedicine && (
                  <>
                    <CheckBoxesAndTextAreaContent
                      header={translations.hn_medicines}
                      otherText={medicationsFreeText}
                      setOtherText={setMedicationsFreeText}
                      showCheckBoxes={false}
                      textAreaLabel={translations.hn_med_label}
                      textAreaPlaceholder="Namn och typ"
                    />
                    <NextButton
                      buttonText={translations.hn_next}
                      disabled={medicationsFreeText === ""}
                      distanceFromScreenBottom="25px"
                      noMargin
                      positionFixed
                      stepSetter={stepSetter}
                    />
                  </>
                )}
              </div>
            )}
          </div>

          {itemsCategories.map((category, index) => {
            const checkBoxPropertiesArray = declarationItems
              .filter(
                (declarationItem) =>
                  declarationItem.declaration_item_category.id === category.id,
              )
              .sort((a, b) =>
                a.attributes.label.localeCompare(b.attributes.label),
              );

            return (
              <div
                key={category.attributes?.label}
                className={`transitionStep ${getClassName(index + 2)}`}
              >
                {step === index + 2 && (
                  <>
                    <CheckBoxesAndTextAreaContent
                      checkBoxPropertiesArray={checkBoxPropertiesArray}
                      header={category.attributes?.label}
                      healthDeclarationTextProperty="medications_free_text"
                      otherText={specialNotes[category.attributes?.label]}
                      setDeclarationItems={setDeclarationItems}
                      setOtherText={(text) => {
                        setSpecialNotes((prevNotes) => ({
                          ...prevNotes,
                          [category.attributes.label]: text || "",
                        }));
                      }}
                      showCheckBoxes
                      textAreaLabel={translations.hn_free_text}
                      textAreaPlaceholder=""
                    />
                    <NextButton
                      buttonText={translations.hn_next}
                      stepSetter={stepSetter}
                    />
                  </>
                )}
              </div>
            );
          })}
          <div
            className={`transitionStep FlexColJustCenter90vh ${getClassName(
              itemsCategories.length + 2,
            )}`}
            style={{ height: "70vh" }}
          >
            {step === itemsCategories.length + 2 && (
              <HealthStateStep
                setPatientHealthEvaluation={setPatientHealthEvaluation}
                stepSetter={stepSetter}
                noMargin
                translations={translations}
              />
            )}
          </div>
          <div
            className={`transitionStep ${getClassName(
              itemsCategories.length + 3,
            )}`}
          >
            {step === itemsCategories.length + 3 && (
              <div>
                <HealthDeclarationPreview
                  declarationItems={declarationItems}
                  itemsCategories={itemsCategories}
                  patientHealthEvaluation={patientHealthEvaluation}
                  medicationsFreeText={medicationsFreeText}
                  specialNotesText={specialNotesText}
                  translations={translations}
                />
                <NextButton
                  buttonText={translations.hn_finish_and_send}
                  placeAtScreenBottom="25px"
                  stepSetter={stepSetter}
                />
              </div>
            )}
          </div>
          <div
            className={`transitionStep ${getClassName(
              itemsCategories.length + 4,
            )}`}
          >
            {step === itemsCategories.length + 4 && (
              <div>
                <HealthDeclarationPageHeader
                  headerHeight="70vh"
                  headerTitle={translations.hn_declaration_done}
                  isMobile={isMobile}
                  showCelebrationIcon
                />
                <NextButton
                  noMargin
                  buttonText={translations.hn_close}
                  placeAtScreenBottom="25px"
                  stepSetter={() => history.push("/start")}
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default HealthDeclarationPage;
