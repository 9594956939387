import React, { memo } from "react";
import * as PropTypes from "prop-types";

import SmileyHappy from "assets/svg-components/smileys/SmileyHappy";
import SmileyNeutral from "assets/svg-components/smileys/SmileyNeutral";
import SmileySad from "assets/svg-components/smileys/SmileySad";
import { PATIENT_HEALTH_EVALUATION } from "constants/health-declaration";

const HealthDeclarationPreview = ({
  declarationItems,
  itemsCategories,
  medicationsFreeText,
  patientHealthEvaluation,
  specialNotesText,
  translations,
}) => {
  return (
    <div className="FlexColJustCenter" style={{ width: "300px" }}>
      {/* -- */}
      <h1
        className="bookingHeader"
        style={{ width: "min-content", marginTop: "40px" }}
      >
        {translations.health_declaration_header_confirm}
      </h1>
      <div
        className="HealthDeclarationSmileyDiv"
        style={{ cursor: "default", paddingTop: "0px" }}
      >
        {itemsCategories?.map((category) => {
          const categoryArray = declarationItems.filter(
            (declarationItem) =>
              declarationItem.declaration_item_category.id === category.id &&
              declarationItem.attributes.value,
          );

          if (!categoryArray.length) {
            return;
          }

          return (
            <div key={category.id}>
              <h2 className="bookingHeaderTextStyle  HealthDeclarationPreviewH2">
                {category.attributes?.label}
              </h2>
              <div style={{ marginTop: "20px" }}>
                {categoryArray.map((item) => (
                  <p
                    key={`${item.id}-${item.attributes?.stub}`}
                    style={{ textAlign: "left" }}
                  >
                    {item.attributes.label}
                  </p>
                ))}
              </div>
            </div>
          );
        })}
        <h2 className="bookingHeaderTextStyle  HealthDeclarationPreviewH2">
          {translations.health_declaration_patient_health_header}
        </h2>
        {patientHealthEvaluation === PATIENT_HEALTH_EVALUATION.bad && (
          <div className="flexRowCenter" style={{ marginTop: "20px" }}>
            <SmileySad classProp="flexStartSelf" />
            <label
              className="HealthDeclarationSmileyLabel"
              style={{ fontSize: "14px", fontWeight: "0", marginLeft: "10px" }}
            >
              {translations.health_declaration_patient_health_bad_label}
            </label>
          </div>
        )}
        {patientHealthEvaluation === PATIENT_HEALTH_EVALUATION.average && (
          <div className="flexRowCenter" style={{ marginTop: "20px" }}>
            <SmileyNeutral classProp="flexStartSelf" />
            <label
              className="HealthDeclarationSmileyLabel"
              style={{ fontSize: "14px", fontWeight: "0", marginLeft: "10px" }}
            >
              {translations.health_declaration_patient_health_average_label}
            </label>
          </div>
        )}
        {patientHealthEvaluation === PATIENT_HEALTH_EVALUATION.good && (
          <div className="flexRowCenter" style={{ marginTop: "20px" }}>
            <SmileyHappy classProp="flexStartSelf" />
            <label
              className="HealthDeclarationSmileyLabel"
              style={{ fontSize: "14px", fontWeight: "0", marginLeft: "10px" }}
            >
              {translations.health_declaration_patient_health_good_label}
            </label>
          </div>
        )}
        {(!!medicationsFreeText || !!specialNotesText) && (
          <h2
            className="bookingHeaderTextStyle  HealthDeclarationPreviewH2"
            style={{ marginBottom: "20px" }}
          >
            {translations.health_declaration_heading_other}
          </h2>
        )}
        {!!medicationsFreeText && (
          <p
            className="noMargin"
            style={{ textAlign: "left", whiteSpace: "normal !important" }}
          >
            {`${translations.hn_medicines}: ${medicationsFreeText}`}
            <br />
            <br />
          </p>
        )}
        {!!specialNotesText && (
          <p style={{ textAlign: "left", whiteSpace: "normal !important" }}>
            {specialNotesText}
          </p>
        )}
      </div>
    </div>
  );
};

HealthDeclarationPreview.propTypes = {
  declarationItems: PropTypes.array,
  itemsCategories: PropTypes.array,
  medicationsFreeText: PropTypes.string,
  patientHealthEvaluation: PropTypes.string,
  specialNotesText: PropTypes.string,
  translations: PropTypes.object,
};

export default memo(HealthDeclarationPreview);
